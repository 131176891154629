.custom-div-icon {
    background: transparent;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    position: relative;
}

.custom-marker {
    width: 40px;
    height: 40px;
    background-color: #A81818; /* Couleur du cercle */
    border-radius: 50%; /* Pour rendre le marqueur rond */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 25px;
    font-weight: bold;
    position: relative;
    font-family: Montserrat, sans-serif, serif;
}

.marker-letter {
    position: relative;
    z-index: 1;
}

.custom-marker:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid var(--marker-after-color, #A81818);
}
