.bg-img {
    background-image: url('../../../../public/images/loginbg.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.login-desc-block {
    background-color: white;
    box-shadow: 0 7px 23px 0 #00000042;
    border-radius: 10px;
    padding: 3rem 5rem;
}