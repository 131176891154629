.message-content {
    color: #1f2937;
    line-height: 1.5;
    padding: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.message-content strong {
    font-weight: 600;
    color: #7C90AA;
}

.message-content .label {
    font-weight: 600;
    color: #4b5563;
}

.message-content .number {
    font-weight: 700;
    color: #7C90AA;
    font-size: 1.125rem;
    margin-right: 0.5rem;
}

.message-content br {
    display: block;
    margin: 0.5rem 0;
}

.message-content .etiquette {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
    font-weight: 700;
    color: white;
    background-color: var(--etiquette-color);
}

/* Couleurs des étiquettes */
.etiquette[data-value="A"] { --etiquette-color: #319834; }
.etiquette[data-value="B"] { --etiquette-color: #33cc31; }
.etiquette[data-value="C"] { --etiquette-color: #fff100; }
.etiquette[data-value="D"] { --etiquette-color: #ff9a00; }
.etiquette[data-value="E"] { --etiquette-color: #ff0000; }
.etiquette[data-value="F"] { --etiquette-color: #cc0000; }
.etiquette[data-value="G"] { --etiquette-color: #840000; }