.price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
    border: 1px solid rgba(226, 232, 240, 0.65);
    border-radius: 15px;
    box-shadow: 0 7px 23px 0 rgba(0, 0, 0, 0.05);
    padding: 40px 30px;
}

@media (max-width: 768px) {
    .price-container {
        margin: 25px auto !important;
    }
}

.price-title {
    font-size: 25px;
    font-weight: 700;
}

.price-description {
    font-size: 14px;
    color: #7C90AA;
    max-width: 85%;
    margin: auto;
    text-align: center;
}

.price-trait {
    border-bottom: 2px dotted #C0D0E8;
    width: 100%;
    display: block;
    margin: 10px 0;
}

.price-container-inner {
    width: 100%;
    margin: 40px 0 20px;
}

.price-container-inner > div {
    width: 100%;
    position: relative;
}

.price-info, .price-info-value {
    width: 50%;
    display: inline-block;
    font-size: 16px;
    padding: 0 5px;
}

.price-info {
    color: #7C90AA;
    font-weight: 500;
    text-align: right;
}

.price-info-value {
    text-align: left;
    width: 50%;
    display: inline-block;
    font-weight: 700;
}

.price-price {
    color: #60AD23;
    font-size: 35px;
    font-weight: 500;
    position: relative;
}

.price-tva {
    font-size: 12px;
    color: #7C90AA;
    font-weight: 500;
    position: absolute;
    bottom: 8px;
    margin-left: 5px;
}

.price-info-bulle {
    position: absolute;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    line-height: 19px;
    font-size: 12px;
    font-weight: bold;
    color: #899BB4;
    background: #E2E8F0;
    right: 15px;
    top: 2px;
    cursor: pointer;
    text-align: center;
}

.price-btn {
    margin: 20px;
}