
.fp-form-container {
    border-radius: 15px;
    box-shadow: 0 7px 23px 0 #0000000D;
    padding: 40px 30px;
    margin: 3rem auto 1rem;
}

.w-input {
    background-color: #F5F6FA;
    border: 1px solid rgba(185, 185, 185, 0.3);
    border-radius: 10px;
    padding: 0 8px;
    line-height: 2.6rem;
}

.w-check {
    background-color: #F5F6FA;
}

.btn-form {
    /*width: 200px;*/
}