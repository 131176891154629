@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import "leaflet/dist/leaflet.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-white;
    @apply font-montserrat;
}

.ban {
    background-color: #F2FDEF;
    width: 100vw;
    color: #60AD23;
    font-size: 18px;
    font-weight: 500;
}

.btn-primary {
    @apply bg-white font-semibold;
    border-color: #7c90aa;
    color: #7c90aa;
}

.btn-secondary {
    @apply text-white font-semibold;
    background-color: #4fb154;
    border-color: #4fb154;
}

.btn-tertiary {
    @apply bg-white font-semibold;
    border-color: #4fb154;
    color: #4fb154;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: #7c90aa;
    color: white;
    box-shadow: none !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active,
.btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary:active
 {
    background-color: #6acc1b;
    border-color: #6acc1b;
    box-shadow: none !important;
    color: white;
}

