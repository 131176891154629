/* Ajouter ces styles dans un fichier CSS global ou dans le fichier CSS Tailwind */

@keyframes slide-up-down {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    20% {
        transform: translateY(0);
        opacity: 1;
    }
    80% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

.animate-slide-up-down {
    animation: slide-up-down 4s ease-in-out forwards;
}

@keyframes fill {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}
