.floating-label-container {
    position: relative;
}

.floating-input {
    background-color: #F5F6FA;
    border: 1px solid rgba(185, 185, 185, 0.15);
    border-radius: 10px;
    padding: 0 8px;
    line-height: 2.6rem;
}

.floating-label {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    transition: all 0.4s ease;
    color: #000;
    cursor: text;
    font-size: 14px;
    line-height: 2.6rem;
}

.floating-label-container.focused .floating-label,
.floating-label-container .floating-input:not(:placeholder-shown) + .floating-label {
    top: -9px;
    left: 10px;
    font-size: 12px;
    color: #000;
    font-weight: 500;
}

.floating-label-container .floating-input:focus {
    outline: none;
    box-shadow: 0 0 3px #35abb7;
}
