.abo {
    margin: 30px auto 0;
    border: 1px solid #E2E8F0;
    box-shadow: 0 7px 23px 0 #0000000D;
    border-radius: 15px;
    padding: 10px;
    width: 356px;
    height: 60px;
    background-color: #f5f6fa;
}

.period {
    font-size: 18px;
    font-weight: 500;
    position: relative;
}

.reduc {
    color: #5FBB46;
    position: absolute;
    bottom: 0;
}