.w-info-container {
    display: flex;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    padding: 1.2rem 2rem;
    margin-top: 2rem;
    width: fit-content;
}

.w-info {
    background-color: #F2FDEF;
    border: 1px dashed #60AD23;
}

.w-error {
    border: 1px dashed #DC0404;
    background: #FF00000D;
}